import { formatNumber } from '@/utils/string'
import { headerItem } from '@/utils/table'
import XIcon from '@/components/daisy/atoms/icons/index.vue'
import DInput from '@/components/daisy/atoms/input/index.vue'
import DSelect from '@/components/daisy/atoms/select/index.vue'
import {
  EXCHANGES,
  DIRECT_ORDER_TYPE_OPTIONS,
  DIRECT_ORDER_SPAN_OPTIONS,
  BUY_SELL_OPTIONS,
} from '@/common/constants.js'

export default function headers(_this) {
  return [
    headerItem('Exchange', 'exchange', {
      cellClass: 'w-80',
      generator: (item) => {
        if (!item.credential) {
          return { value: '-' }
        }
        const credential = item.credential
        const exchange = credential.exchange || '-'
        return {
          value: `
          <div class="grid grid-cols-4 gap-1 text-xs">
            <div>Exchange:</div>
            <div class="col-span-3 text-right">
              ${EXCHANGES[exchange] || exchange}
            </div>
            <div>${credential.productId ? 'ProductID' : 'UID'}:</div>
            <div class="col-span-3 text-right">
              ${credential.productId || credential.uid}
            </div>
            <div class="col-span-2 flex items-center justify-start space-x-4">
              <span>Balance:</span>
              <span class="text-right">
                ${formatNumber(item.credential?.balance || 0)}
              </span>
            </div>
            <div class="col-span-2 flex items-center justify-end space-x-4">
              <span>Equity:</span>
              <span class="text-right">
                ${formatNumber(item.credential?.equity || 0)}
              </span>
            </div>
          </div>`,
        }
      },
    }),
    headerItem('Account', 'name', {
      cellClass: 'w-48',
      generator: (item) => {
        return {
          component: DSelect,
          props: {
            value: item.credential?._id || '',
            items: _this.accountOptions,
          },
          events: {
            input: (value) => {
              const credential = _this.accountMap[value]
              _this.setValue(item.index, 'credential', credential)
            },
          },
        }
      },
    }),
    headerItem('Order Type', 'orderType', {
      class: 'w-36',
      generator: (item) => {
        return {
          component: DSelect,
          props: {
            value: item.orderType,
            items: DIRECT_ORDER_TYPE_OPTIONS,
          },
        }
      },
    }),
    headerItem('Side', 'side', {
      class: 'w-28',
      generator: (item) => {
        return {
          component: DSelect,
          props: {
            value: item.side,
            items: BUY_SELL_OPTIONS,
          },
          events: {
            input: (value) => {
              _this.setValue(item.index, 'side', parseInt(value))
            },
          },
        }
      },
    }),
    headerItem('Volume', 'volume', {
      generator: (item) => {
        return {
          component: DInput,
          props: {
            class: 'w-24',
            value: item.volume,
          },
          events: {
            onChange: (value) => {
              const _value = parseFloat(value)
              _this.setValue(item.index, 'volume', isNaN(_value) ? 0 : _value)
            },
          },
        }
      },
    }),
    headerItem('Stop Price', 'stopPrice', {
      generator: (item) => {
        return {
          component: DInput,
          props: {
            class: 'w-24',
            value: item.price,
          },
          events: {
            onChange: (value) => {
              const _value = parseFloat(value)
              _this.setValue(
                item.index,
                'stopPrice',
                isNaN(_value) ? 0 : _value
              )
            },
          },
        }
      },
    }),
    headerItem('Span', 'span', {
      class: 'w-32',
      generator: (item) => {
        return {
          component: DSelect,
          props: {
            value: item.span,
            items: DIRECT_ORDER_SPAN_OPTIONS,
          },
          events: {
            input: (value) => {
              _this.setValue(item.index, 'span', parseInt(value))
            },
          },
        }
      },
    }),
    headerItem('Delay (seconds)', 'delay', {
      generator: (item) => {
        return {
          component: DInput,
          props: {
            class: 'w-full',
            value: item.delay,
          },
          events: {
            onChange: (value) => {
              const _value = parseFloat(value)
              _this.setValue(item.index, 'delay', isNaN(_value) ? 0 : _value)
            },
          },
        }
      },
    }),
    headerItem('Delete', 'delete', {
      generator: (item) => {
        return {
          component: XIcon,
          props: {
            class: 'btn-error btn-circle btn-xs',
            name: 'x',
          },
          events: {
            click: () => {
              _this.remove(item.index)
            },
          },
        }
      },
    }),
  ]
}
