<template>
  <delete-modal :label="label" :handler="remove" />
</template>

<script>
import { credentialsActions } from '@/store/constants.js'
import DeleteModal from '@/components/misc/delete-modal/index.vue'

export default {
  name: 'delete-account',

  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  components: {
    DeleteModal,
  },

  computed: {
    label() {
      return `
        <div class="font-medium text-lg">
          ${this.$t('Delete Group')}
          <span class="font-bold">${this.group.name}</span>
        </div>`
    },
  },

  methods: {
    async remove() {
      await this.$http.delete(`/api/groups/${this.group._id}`)
      this.$store.dispatch(`credentials/${credentialsActions.LOAD_GROUPS}`, {
        force: true,
      })
    },
  },
}
</script>
