<template>
  <div>
    <div class="font-medium text-lg" v-html="label" />

    <div class="modal-action">
      <button class="btn btn-sm btn-error" @click.stop="wrapperHandler">
        {{ $t('Delete') }}
      </button>
      <button class="btn btn-sm" @click.stop="$$closeModal">
        {{ $t('Cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'delete-modal',

  props: {
    label: {
      type: String,
      required: true,
    },
    handler: {
      type: Function,
      required: true,
    },
  },

  methods: {
    async wrapperHandler(event) {
      await this.handler()
      this.$$closeModal(event)
    },
  },
}
</script>
