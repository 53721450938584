<template>
  <div class="flex-between space-x-4">
    <x-icon
      @click.stop="$emit('onEdit')"
      class="btn-primary rounded-full h-5 w-5"
      name="pencil"
    />
    <x-icon
      v-if="account.order > 1"
      @click.stop="$emit('onMoveUp')"
      class="btn-warning rounded-full h-5 w-5"
      name="arrow-up-circle"
    />
    <x-icon
      v-if="account.order < total"
      @click.stop="$emit('onMoveDown')"
      class="btn-warning rounded-full h-5 w-5"
      name="arrow-down-circle"
    />
    <x-icon
      @click.stop="$emit('onDelete')"
      class="btn-error rounded-full h-5 w-5"
      name="x"
    />
  </div>
</template>

<script>
import XIcon from '@/components/daisy/atoms/icons/index.vue'
export default {
  name: 'edit-account',

  components: {
    XIcon,
  },

  props: {
    total: Number,
    account: Object,
  },
}
</script>
