import { headerItem } from '@/utils/table'
import { EXCHANGE_NAMES } from '@/common/constants.js'
import DBtn from '@/components/daisy/atoms/button/index.vue'
import DInput from '@/components/daisy/atoms/input/index.vue'
import DSelect from '@/components/daisy/atoms/select/index.vue'

export default function headers(_this) {
  return [
    headerItem('Account', 'account', {
      cellClass: 'w-48 text-xs font-semibold',
      generator: (account) => {
        if (account.productId && account.exchange === 'bitcopy') {
          return { value: `${account.productId} (bitcopy)` }
        }
        return { value: `
          <div>
            ${account.name || '-'}
            <br/>
            (${EXCHANGE_NAMES[account.exchange] || '-'})
          </div>` }
      },
    }),
    headerItem('Auto Entry', 'auto', {
      cellClass: 'w-24',
      generator: (account) => ({
        component: DSelect,
        props: {
          value: account.autoEntry ? 1 : 0,
          items: [{ value: 1, label: 'ON' }, { value: 0, label: 'OFF' }],
        },
        events: {
          input: (value) => {
            account.autoEntry = value === 1
          },
        },
      }),
    }),
    headerItem('Multiple', 'multiple', {
      cellClass: 'w-24 text-right',
      generator: (account) => {
        return {
          component: DInput,
          props: {
            class: 'w-24 text-right',
            type: 'number',
            value: account.autoEntryConfig?.multi || 1,
          },
          events: {
            onChange: (value) => {
              account.autoEntryConfig.multi = parseFloat(value)
            },
          },
        }
      },
    }),
    headerItem('RANDOM (0.9~1.1)', 'random', {
      cellClass: 'w-24 text-right',
      generator: (account) => {
        return {
          component: DInput,
          props: {
            class: 'w-24 text-right',
            type: 'number',
            value: account.autoEntryConfig?.RANDOM || 0.9,
          },
          events: {
            onChange: (value) => {
              account.autoEntryConfig.RANDOM = parseFloat(value)
            },
          },
        }
      },
    }),
    headerItem('Exit Day Range (Lower)', 'lower', {
      cellClass: 'w-24 text-right',
      generator: (account) => {
        return {
          component: DInput,
          props: {
            class: 'w-24 text-right',
            type: 'number',
            value: account.autoEntryConfig.exitAfter || 1,
          },
          events: {
            onChange: (value) => {
              account.autoEntryConfig.exitAfter = parseInt(value)
            },
          },
        }
      },
    }),
    headerItem('Exit Day Range (Upper)', 'upper', {
      cellClass: 'w-24 text-right',
      generator: (account) => {
        return {
          component: DInput,
          props: {
            class: 'w-24 text-right',
            type: 'number',
            value: account.autoEntryConfig.exitAfter || 3,
          },
          events: {
            onChange: (value) => {
              account.autoEntryConfig.exitBefore = parseInt(value)
            },
          },
        }
      },
    }),
    headerItem('Memo', 'memo', {
      cellClass: 'w-full',
      generator: (account) => {
        return {
          component: DInput,
          props: {
            class: 'w-full',
            value: account.autoEntryConfig?.memo || '',
          },
          events: {
            onChange: (value) => {
              account.autoEntryConfig.memo = value
            },
          },
        }
      },
    }),
    headerItem('', 'save', {
      generator: (account) => {
        return {
          component: DBtn,
          props: {
            color: 'primary',
            size: 'xs',
            text: 'Save',
          },
          events: {
            click: () => {
              _this.save(account)
            },
          },
        }
      },
    }),
  ]
}
