<template>
  <div class="max-w-full w-11/12 h-full flex flex-col space-y-4 relative">
    <div class="flex items-center justify-between">
      <span class="font-medium text-lg">
        {{ $t('Auto Entry') }}
      </span>
    </div>
    <div class="flex-end w-full mt-4 mb-1">
      <button class="btn-sm-warning" @click.stop="close">Close</button>
    </div>
    <d-table :headers="headers" :items="accounts" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import headersGenerator from './headers.js'

export default {
  name: 'auto-entry-modal',

  data: () => ({
    accounts: [],
  }),

  computed: {
    ...mapGetters({
      credentials: 'credentials/credentials',
    }),
    headers() {
      return headersGenerator(this)
    },
  },

  mounted() {
    this.accounts = this.credentials
      .filter((el) => {
        if (el.exchange === 'paper') {
          return false
        }
        if (el.exchange === 'bybit') {
          return false
        }
        return true
      })
      .map((el) => ({
        _id: el._id,
        name: el.name,
        exchange: el.exchange,
        autoEntry: el.autoEntry,
        autoEntryConfig: el.autoEntryConfig || {},
      }))
  },

  methods: {
    close(event) {
      this.$$closeModal(event)
    },
    async save(account) {
      this.$store.commit('common/INCREASE_COUNTER')
      await this.$http.post('/api/v2/credentials/auto-entry', account)
      this.$store.commit('common/DECREASE_COUNTER')
    },
  },
}
</script>
