<template>
  <div>
    <div class="font-medium text-lg">
      {{ editMode ? $t('Edit Account') : $t('New Account') }}
    </div>
    <div class="font-medium text-info text-right">
      {{ $t('Please enable access from IP: 13.230.42.149') }}
    </div>

    <div class="h-96 overflow-y-scroll p-2">
      <div class="form-control" v-for="config in configs" :key="config.key">
        <label class="label">
          <span class="label-text" v-text="config.label" />
        </label>

        <select
          v-if="config.type === 'SELECT'"
          :class="error && errors[config.key] ? 'select-error' : ''"
          class="select select-bordered w-full border-solid"
          v-model="input[config.key]"
        >
          <option disabled="disabled" selected="selected">
            {{ config.placeholder }}
          </option>
          <option
            v-for="{ label, value } in config.options"
            :key="value"
            :value="value"
          >
            {{ label }}
          </option>
        </select>
        <input
          v-else
          type="text"
          class="input-box"
          :class="error && errors[config.key] ? 'input-box-error' : ''"
          v-model.trim="input[config.key]"
        />
      </div>
    </div>

    <div class="modal-action">
      <button class="btn btn-sm" @click="$$closeModal">
        {{ $t('Cancel') }}
      </button>
      <button class="btn-sm-primary" @click.stop="push">
        {{ editMode ? $t('Update') : $t('Create') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { credentialsActions } from '@/store/constants.js'
import { EXCHANGE_OPTIONS } from '@/common/constants.js'
import { isHaveApiKey, isHaveToken } from '@/utils/exchange/index.js'

const MASKING_KEYS = ['token', 'key', 'secret']

export default {
  name: 'create-edit-account',

  props: {
    account: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    id: null,
    error: false,
    errors: {},
    input: {
      name: '',
      exchange: '',
      token: '',
      productId: 0,
      key: '',
      secret: '',
      group: '',
      memo: '',
    },
  }),

  computed: {
    ...mapGetters({
      groupOptions: 'credentials/groupOptions',
    }),
    credential() {
      return this.account
    },
    configs() {
      const userId = this.$store.state.auth.user._id
      const isAllExchange = [
        '6485faa87be4b911b8ded129',
        '612e77d725fc362f0757e151',
      ].includes(userId)
      let base = [
        {
          key: 'name',
          label: this.$t('Account Name'),
        },
        {
          key: 'group',
          type: 'SELECT',
          label: this.$t('Group'),
          options: this.groupOptions,
          placeholder: this.$t('Please select group'),
        },
        {
          key: 'exchange',
          type: 'SELECT',
          label: this.$t('Exchange'),
          options: EXCHANGE_OPTIONS.filter((option) => {
            return option.public || isAllExchange
          }),
          placeholder: this.$t('Please select exchange'),
        },
      ]
      if (this.hasApiKey) {
        base = base.concat([
          {
            key: 'key',
            label: this.$t('API key'),
          },
          {
            key: 'secret',
            label: this.$t('Secret key'),
          },
        ])
      }
      if (this.hasToken) {
        base = base.concat([
          {
            key: 'token',
            label: this.$t('Token'),
          },
          {
            key: 'productId',
            label: this.$t('Product ID'),
          },
        ])
      }
      base.push({ key: 'memo', label: this.$t('Memo') })
      return base
    },
    editMode() {
      return !!this.id
    },
    exchange() {
      return this.input?.exchange || ''
    },
    hasApiKey() {
      return isHaveApiKey(this.exchange)
    },
    hasToken() {
      return isHaveToken(this.exchange)
    },
  },

  mounted() {
    if (this.credential) {
      this.id = this.credential._id || ''
      this.input.name = this.credential.name || ''
      this.input.exchange = this.credential.exchange || ''
      this.input.productId = this.credential.productId || ''
      MASKING_KEYS.forEach((key) => {
        this.input[key] = _masking(this.credential[key] || '')
      })
      this.input.group = this.credential.group || ''
      this.input.memo = this.credential.memo || ''
    }
  },

  methods: {
    validate(payload) {
      const errors = {}
      let result = true
      ;['name', 'exchange', 'group'].map((key) => {
        if (!payload[key]) {
          errors[key] = true
          result = false
        }
      })

      if (this.hasToken && !payload.token) {
        errors.token = true
        result = false
      }

      if (this.hasApiKey) {
        if (!payload.key) {
          errors.key = true
          result = false
        }
        if (!payload.secret) {
          errors.secret = true
          result = false
        }
      }
      this.errors = errors
      return result
    },
    async push(event) {
      const payload = this.input
      this.error = !this.validate(payload)
      if (!this.error) {
        payload.token = payload.token.replace('Bearer ', '')
        MASKING_KEYS.forEach((key) => {
          if (_isMasking(payload[key])) {
            delete payload[key]
          }
        })
        if (this.editMode) {
          await this.$http.put(`/api/v2/account/${this.id}`, payload)
        } else {
          await this.$http.post('/api/v2/account', payload)
        }
        await this.$store.dispatch(
          `credentials/${credentialsActions.LOAD_CREDENTIALS}`,
          {
            force: true,
          }
        )
        this.$$closeModal(event)
      }
    },
  },
}

const mask = '**********'
function _isMasking(value = '') {
  return value.includes(mask)
}

function _masking(secret) {
  return (secret || '').length > 6 ? secret.substring(0, 5) + mask : mask
}
</script>
