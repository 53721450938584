<template>
  <div class="flex-between w-full">
    <label class="label mr-4">
      <span class="label-text font-bold">{{ $t('Status') }}</span>
    </label>
    <div class="form-control w-full flex-end">
      <input
        type="checkbox"
        :checked="logic.enable"
        class="toggle border-solid toggle-primary"
        @click.stop="onClick"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'logic-name',

  props: {
    logic: Object,
  },

  methods: {
    async onClick(event) {
      this.$store.commit('common/INCREASE_COUNTER')
      await this.$http.put(`/api/v2/logics/${this.logic._id}`, {
        enable: !this.logic.enable,
      })
      await this.$store.dispatch('logic/loadLogics', { force: true })
      this.$store.commit('common/DECREASE_COUNTER')
      this.$$closeModal(event)
    },
  },
}
</script>
