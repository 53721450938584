<template>
  <div class="collapse collapse-wrapper">
    <input type="checkbox" v-model="isOpen" />
    <div class="collapse-title">
      <div class="flex">
        <div class="text-xl font-bold">
          {{ group.name }}
        </div>
      </div>

      <div class="flex mt-2 w-full items-center">
        <div class="flex md:items-center gap-2 flex-col md:flex-row">
          <div class="text-md font-medium">
            <span> {{ $t('accounts') }}: </span>
            <span>
              {{ group.total }}
            </span>
          </div>

          <div class="divider divider-vertical" v-if="!isMobile" />

          <div class="text-md font-medium">
            <span> {{ $t('balance') }}: </span>
            <span>
              {{ total.balance | formatNumber }}
              <span class="text-gray-600">USD</span>
            </span>
            <br />
            <span> {{ $t('Equity') }}: </span>
            <span>
              {{ total.equity | formatNumber }}
              <span class="text-gray-600">USD</span>
            </span>
          </div>
        </div>

        <div class="ml-auto flex flex-col md:flex-row" v-if="!isMobile">
          <d-btn class="z-10" color="primary" size="xs" @click="editGroup">
            <d-icon name="pencil" size="sm" />
            {{ $t('Edit') }}
          </d-btn>
          <d-btn
            class="z-10"
            color="warning"
            size="xs"
            @click="moveGroupUp"
            v-if="group.order > 1"
          >
            <d-icon name="arrow-up-circle" size="sm" />
            {{ $t('Move UP') }}
          </d-btn>
          <d-btn
            class="z-10"
            color="warning"
            size="xs"
            @click="moveGroupDown"
            v-if="group.order < total"
          >
            <d-icon name="arrow-down-circle" size="sm" />
            {{ $t('Move DOWN') }}
          </d-btn>
          <d-btn
            class="z-10"
            color="error"
            size="xs"
            v-if="!hasAccount"
            @click="removeGroup"
          >
            <d-icon name="x" size="sm" />
            {{ $t('Delete') }}
          </d-btn>
        </div>
      </div>
    </div>

    <div class="collapse-content">
      <div
        :class="['collapse-content-inner', isMobile ? 'max-h-1/2' : '']"
        :key="group.key"
      >
        <d-table
          class="h-full overflow-scroll"
          :headers="headers"
          :items="accounts"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { credentialsActions } from '@/store/constants.js'
import EditAccount from '@/components/apps/accounts/create-edit-account/index.vue'
import EditAccountGroup from '@/components/apps/accounts/create-edit-account-group/index.vue'
import EditGroup from '@/components/apps/accounts/create-edit-group/index.vue'
import DeleteGroup from '@/components/apps/accounts/delete-group/index.vue'
import DeleteAccount from '@/components/apps/accounts/delete-account/index.vue'
import NewOrder from '@/components/apps/accounts/manual-order/index.vue'
import AccountLogics from '@/components/apps/accounts/account-logics-modal/index.vue'
import headersGenerator from './headers.js'

export default {
  name: 'group-card',

  props: {
    group: Object,
    chartType: String,
  },

  data: () => ({
    isMobile: global.IS_MOBILE,
    isOpen: false,
  }),

  mounted() {
    this.isOpen = localStorage[`account.open.${this.group.name}`] === '1'
  },

  watch: {
    isOpen(value) {
      if (value) {
        localStorage[`account.open.${this.group.name}`] = '1'
      } else {
        delete localStorage[`account.open.${this.group.name}`]
      }
    },
  },

  computed: {
    ...mapGetters({
      price: 'market/btcusd',
      numberOfLogicById: 'logic/numberOfLogicById',
    }),
    accounts() {
      return this.group?.accounts || []
    },
    headers() {
      return headersGenerator(this)
    },
    hasAccount() {
      return this.group.total > 0
    },
    total() {
      return this.group.accounts.reduce(
        (acc, el) => {
          const k = el.isCoinBase ? 1 : this.price
          acc.balance += parseFloat(el.balance || 0) * k
          acc.equity += parseFloat(el.equity || 0) * k
          return acc
        },
        { balance: 0, equity: 0 }
      )
    },
  },

  methods: {
    invalid(account) {
      // TODO Hau: use constants
      return account.status !== 1
    },
    editAccount(account) {
      if (account.isGroup) {
        this.$$showModal({
          component: EditAccountGroup,
          props: { account },
        })
      } else {
        this.$$showModal({
          component: EditAccount,
          props: { account },
        })
      }
    },
    editGroup() {
      this.$$showModal({
        component: EditGroup,
        props: { group: this.group },
      })
    },
    loadLogicsInformation(accountId) {
      this.$$showModal({
        component: AccountLogics,
        props: { accountId },
      })
    },
    removeAccount(account) {
      this.$$showModal({
        component: DeleteAccount,
        props: { account },
      })
    },
    removeGroup() {
      this.$$showModal({
        component: DeleteGroup,
        props: { group: this.group },
      })
    },
    moveGroupUp() {
      this.$store.dispatch(
        `credentials/${credentialsActions.RE_ORDER_GROUPS}`,
        {
          group: this.group,
          change: -1,
        }
      )
    },
    moveGroupDown() {
      this.$store.dispatch(
        `credentials/${credentialsActions.RE_ORDER_GROUPS}`,
        {
          group: this.group,
          change: 1,
        }
      )
    },
    moveAccountUp(account) {
      this.$store.dispatch(
        `credentials/${credentialsActions.RE_ORDER_ACCOUNTS}`,
        {
          account,
          change: -1,
        }
      )
    },
    moveAccountDown(account) {
      this.$store.dispatch(
        `credentials/${credentialsActions.RE_ORDER_ACCOUNTS}`,
        {
          account,
          change: 1,
        }
      )
    },
    orderable(account) {
      return account.exchange === 'paper' ? false : !this.invalid(account)
    },
    order(account) {
      this.$$showModal({
        component: NewOrder,
        props: { account },
      })
    },
  },
}
</script>
