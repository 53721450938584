import { isCoinBase } from '@/utils/exchange'
import { headerItem } from '@/utils/table'
import LogicChart from '@/components/apps/logics/chart/index.vue'
import LogicName from './LogicName.vue'

import {
  FIELDS,
  CUSTOM_FIELDS,
  DISPLAY_ORDERS,
} from '@/common/logic-configs.js'

const FIELDS_BY_CODE = {}

export default function headers() {
  return [
    headerItem('Logic', 'name', {
      cellClass: 'w-32',
      generator: (logic) => {
        return { value: logic.name }
      },
    }),
    headerItem('Config', 'config', {
      generator: (logic) => {
        const _configs = configs({
          code: logic.code,
          config: logic.config,
          exchange: logic.exchange,
        })
        const value = _configs
          .map((el) => {
            const key = el.key
            if (key === 'volume') {
              const isCoin = isCoinBase(logic.exchange)
              const label = isCoin ? 'Entry lot (in Coin)' : 'Entry lot (in USD)'
              const value = logic.config?.[key]
              return `<span class="text-xs">${label}: ${value.toLocaleString()}</span>`
            }
            if (key && logic.config?.[key]) {
              let value = logic.config?.[key]

              if (el.field.type === 'select') {
                if (Array.isArray(el.field.items)) {
                  const record = el.field.items.find((el) => el.value === value)
                  value = record?.text || value
                }
              }

              if (el.field?.label?.includes('in minutes')) {
                value = parseInt(value)
                value = value > 60e3 - 1 ? value / 60e3 : value
                value = `${value.toLocaleString()} min`

              }

              if (parseInt(value).toString() === value.toString()) {
                value = parseInt(value).toLocaleString()
              }

              return `<span class="text-xs">${el.field.label}: ${value}</span>`
            }
          })
          .filter(Boolean)
          .join('<br/>')
        return { value }
      },
    }),
    headerItem('Summary', 'overview', {
      generator: (logic) => ({
        component: LogicChart,
        props: {
          logic,
        },
      }),
    }),
    headerItem('Action', '', {
      cellClass: 'font-bold',
      generator: (logic) => ({
        component: LogicName,
        props: { logic },
      }),
    }),
  ]
}

function configs({ code, config, exchange }) {
  const orders = DISPLAY_ORDERS[code] || []
  return Object.keys(config || {})
    .sort((a, b) => (orders[a] || 0) - (orders[b] || 0))
    .map((key) => ({ key, field: configField({ key, exchange, code }) }))
    .filter((config) => config.field)
}

function configField({ key, exchange, code }) {
  if (key === 'productId' && exchange !== 'bitcopy') {
    return null
  }
  const config = fields(code).find((logic) => logic.key === key)
  return config?.hidden ? null : config
}

function fields(code) {
  if (!FIELDS_BY_CODE[code]) {
    const fields = { ...FIELDS }
    ;(CUSTOM_FIELDS[code] || []).forEach((el) => {
      fields[el.key] = el
    })
    FIELDS_BY_CODE[code] = Object.values(fields)
  }
  return FIELDS_BY_CODE[code]
}
