<template>
  <div>
    <div class="font-medium text-lg">
      {{ editMode ? $t('Edit Group') : $t('New Group') }}
    </div>
    <div class="form-control" v-for="config in configs" :key="config.key">
      <label class="label">
        <span class="label-text" v-text="config.label" />
      </label>
      <input
        type="text"
        :class="error && !input[config.key] ? 'input-error' : ''"
        class="input input-bordered w-full border-solid"
        v-model.trim="input[config.key]"
      />
    </div>

    <div v-if="error" class="text-error my-2 font-bold text-right w-full">
      {{ $t('Name is required') }}
    </div>

    <div class="modal-action">
      <button class="btn btn-sm" @click="$$closeModal">
        {{ $t('Cancel') }}
      </button>
      <button class="btn-sm-primary" @click.stop="push">
        {{ editMode ? $t('Update') : $t('Create') }}
      </button>
    </div>
  </div>
</template>

<script>
import { credentialsActions } from '@/store/constants.js'
export default {
  name: 'create-edit-group',

  props: {
    group: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    id: null,
    error: false,
    input: {
      name: '',
    },
  }),

  computed: {
    configs() {
      return [
        {
          key: 'name',
          label: this.$t('Account Name'),
        },
      ]
    },
    editMode() {
      return !!this.id
    },
  },

  mounted() {
    if (this.group) {
      this.id = this.group._id
      this.input.name = this.group.name
    }
  },

  methods: {
    validate(payload) {
      return !!payload.name
    },
    async push(event) {
      const payload = this.input
      this.error = !this.validate(payload)
      if (!this.error) {
        if (this.editMode) {
          await this.$http.put(`/api/groups/${this.id}`, payload)
        } else {
          await this.$http.post('/api/groups', payload)
        }
        this.$store.dispatch(`credentials/${credentialsActions.LOAD_GROUPS}`, {
          force: true,
        })
        this.$$closeModal(event)
      }
    },
  },
}
</script>
