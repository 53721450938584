<template>
  <div class="max-w-full w-11/12 h-full flex flex-col space-y-4 relative">
    <div class="flex items-center justify-between">
      <span class="font-medium text-lg">
        {{ $t('Logics') }}
      </span>
      <button class="btn-sm-primary" @click.stop="toSetting">Setting</button>
    </div>
    <d-table :headers="headers" :items="logics" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import headersGenerator from './headers.js'

export default {
  name: 'account-logics-modal',

  props: {
    accountId: String,
  },

  computed: {
    ...mapGetters({
      logicByAccountId: 'logic/logicByAccountId',
    }),
    headers() {
      return headersGenerator(this)
    },
    logics() {
      return this.logicByAccountId[this.accountId].filter((el) => {
        return el.name !== 'Manual order'
      })
    },
  },

  methods: {
    toSetting(event) {
      this.$router.push(`/my-bots?accountId=${this.accountId}`)
      this.$$closeModal(event)
    },
  },
}
</script>
