<template>
  <div class="h-96 flex-center text-4xl">
    {{ content || $t('No available data') }}
  </div>
</template>

<script>
export default {
  name: 'empty-content',

  props: {
    content: String,
  },
}
</script>
