<template>
  <div class="max-w-full w-11/12 h-full flex flex-col space-y-4 relative">
    <div :class="['flex items-center justify-between', opacity]">
      <div class="font-medium text-lg">
        {{ $t('Batch Entry') }}
      </div>
      <div class="flex items-center justify-between">
        <div class="w-64 mr-8" v-if="templates.length > 0">
          <d-select
            v-model="templateId"
            :items="templates"
            @input="onSelectTemplate"
          />
        </div>
        <div
          class="flex items-center justify-between space-x-2 mr-8"
          v-if="inputs.length > 1"
        >
          <span class="font-medium">
            {{ $t('Batch edit') }} ({{ inputs.length }})
          </span>
          <input
            v-model="isBatchEdit"
            type="checkbox"
            checked="checked"
            class="toggle border-solid toggle-primary"
          />
        </div>
        <button class="btn-sm-primary" @click.stop="add">
          {{ $t('Add') }}
        </button>
      </div>
    </div>
    <div :class="['flex-grow overflow-scroll border', opacity]" :key="key">
      <d-table :headers="headers" :items="inputs" />
    </div>
    <div
      v-if="mode === 2"
      class="absolute flex items-center justify-center w-full h-full z-100"
    >
      <div class="w-64 flex flex-col items-center justify-center">
        <label class="w-64 label">
          <span class="label-text font-semibold text-lg"> Template name: </span>
        </label>
        <input
          type="text"
          class="input input-bordered w-full border-solid"
          v-model="templateName"
        />
        <div class="flex items-center justify-center space-x-4 mt-8">
          <button class="btn-sm-primary w-32" @click.stop="saveMemo">
            {{ $t('Save') }}
          </button>
          <button class="btn-sm-warning" @click.stop="() => (mode = NORMAL)">
            {{ $t('Cancel') }}
          </button>
        </div>
      </div>
    </div>
    <div :class="['flex items-center justify-center space-x-4', opacity]">
      <div class="w-full">&nbsp;</div>
      <button class="btn-sm-primary" @click.stop="save">
        {{ $t('Save') }}
      </button>
      <button class="btn-sm-warning" @click="$$closeModal">
        {{ $t('Cancel') }}
      </button>
      <div class="w-full flex items-center justify-end">
        <template v-if="credentialIds.length > 0">
          <button class="btn-sm-info" @click="saveMemo" v-if="!templateId">
            {{ $t('Save Memo') }}
          </button>
          <button class="btn-sm-error" @click="removeMemo" v-else>
            {{ $t('Remove Memo') }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { configsActions } from '@/store/constants.js'
import batchMixin from '@/mixins/batch.js'
import headersGenerator from './headers.js'
import { symbols as exchangeSymbols } from '@/utils/exchange'

const _default = {
  credential: null,
  stopOrder: false,
  limitOrder: false,
  side: 1,
  volume: 0,
  price: 0,
  span: 30e3,
  delay: 0,
  memo: '',
}

const [NORMAL, SAVE_TEMPLATE] = [1, 2]

export default {
  name: 'batch-order-modal',
  mixins: [batchMixin],

  data: () => ({
    templateId: null,
    mode: NORMAL,
    templateName: '',
    NORMAL,
    SAVE_TEMPLATE,
  }),

  computed: {
    ...mapGetters({
      manualOrders: 'configs/manualOrders',
      price: 'market/btcusd',
      accountMap: 'credentials/accountMap',
      accountOptions: 'accountOptions',
    }),
    credentialIds() {
      return this.inputs.map((el) => el?.credential?._id).filter(Boolean)
    },
    templates() {
      return this.manualOrders.map((el) => ({
        value: el.id,
        label: el.name,
      }))
    },
    opacity() {
      return this.mode === SAVE_TEMPLATE ? 'opacity-20' : ''
    },
    headers() {
      return headersGenerator(this)
    },
    _default() {
      return _default
    },
  },

  methods: {
    add(...args) {
      this.templateId = null
      this.addInput(...args)
    },
    remove(...args) {
      this.templateId = null
      this.removeInput(...args)
    },
    onSelectTemplate(id) {
      const template = this.manualOrders.find((el) => el.id === id)
      global.debug(this.manualOrders, template)

      this.inputs = template.credentialIds
        .map((id) => {
          if (this.accountMap[id]) {
            return {
              ..._default,
              credential: this.accountMap[id],
            }
          }
          return null
        })
        .filter(Boolean)
    },
    async removeMemo() {
      if (!this.templateId) {
        return
      }
      await this.$store.dispatch(`configs/${configsActions.SAVE}`, {
        manualOrders: this.manualOrders.filter(
          (el) => el.id !== this.templateId
        ),
      })
      this.$toast.success(this.$t('Updated'))
      this.templateId = null
    },
    async saveMemo() {
      if (this.mode === NORMAL) {
        this.templateName = ''
        this.mode = SAVE_TEMPLATE
        return
      }
      this.templateId = Date.now()
      await this.$store.dispatch(`configs/${configsActions.SAVE}`, {
        manualOrders: [
          ...this.manualOrders,
          {
            id: this.templateId,
            name: this.templateName,
            credentialIds: this.credentialIds,
          },
        ],
      })
      this.$toast.success(this.$t('Updated'))
      this.mode = NORMAL
    },
    async save(event) {
      const now = Date.now()
      const payload = this.inputs
        .map((el) => {
          const credentialId = el.credential?._id
          if (!this.accountMap[credentialId]) {
            return null
          }
          const credential = el.credential
          const symbols = exchangeSymbols(credential.exchange)
          const symbol = symbols[0] || ''
          if (!symbol) {
            return null
          }
          const stopOrder = parseInt(el.orderType) === 2
          const limitOrder = parseInt(el.orderType) === 3
          return {
            symbol,
            credential: credentialId,
            volume: parseFloat(el.volume),
            side: parseInt(el.side),
            reduceOnly: false,
            span: parseInt(el.span),
            productId: credential.productId,
            exchange: credential.exchange,
            stopOrder,
            limitOrder,
            price: stopOrder ? parseFloat(el.price) : 0,
            status: 1,
            after: now + el.delay * 1e3,
          }
        })
        .filter(Boolean)
      await this.$http.post('/api/order/manual/batch', payload)
      this.$$closeModal(event)
      this.$router.push({ name: 'manual-orders' })
    },
  },
}
</script>
