<template>
  <div>
    <div class="font-medium text-lg">
      {{ editMode ? $t('Edit Account Group') : $t('New Account Group') }}
    </div>
    <div class="h-96 overflow-y-scroll p-2">
      <div class="form-control" v-for="config in configs" :key="config.key">
        <label class="label">
          <span class="label-text" v-text="config.label" />
        </label>

        <select
          v-if="config.type === 'SELECT'"
          :class="error && errors[config.key] ? 'select-error' : ''"
          class="select select-bordered w-full border-solid"
          v-model="input[config.key]"
        >
          <option disabled="disabled" selected="selected">
            {{ config.placeholder }}
          </option>
          <option
            v-for="{ label, value } in config.options"
            :key="value"
            :value="value"
          >
            {{ label }}
          </option>
        </select>
        <input
          v-else
          type="text"
          class="input-box"
          :class="error && errors[config.key] ? 'input-box-error' : ''"
          v-model.trim="input[config.key]"
        />
      </div>
      <div class="form-control" :key="counter">
        <label class="label">
          <span class="label-text"> Account & ratio (%) </span>
        </label>
        <div
          class="text-xs p-2 hover:bg-base-300 rounded-md flex justify-start items-center"
        >
          <label class="ml-2 w-20">
            <span class="item-text">Filter</span>
          </label>
          <input
            class="input input-bordered input-sm w-full border-solid"
            v-model="keyword"
          />
        </div>
        <div class="h-64 overflow-y-scroll mt-2">
          <div
            class="text-xs p-2 hover:bg-base-300 rounded-md"
            v-for="account of accounts"
            :key="account.value"
          >
            <div class="w-full flex items-center justify-start">
              <input
                type="checkbox"
                class="checkbox border-solid checkbox-sm"
                :id="`${account.value}.account`"
                :checked="Boolean(input.groupConfig[account.value])"
                @click.stop="() => toggle(account.value)"
              />
              <label :for="`${account.value}.account`" class="ml-2 w-44">
                <span class="item-text">{{ account.label }}</span>
              </label>
              <input
                class="input input-bordered input-sm w-full border-solid"
                :disabled="!Boolean(input.groupConfig[account.value])"
                placeholder="Ratio (%)"
                v-model="input.groupConfig[account.value]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-action">
      <button class="btn btn-sm" @click="$$closeModal">
        {{ $t('Cancel') }}
      </button>
      <button class="btn-sm-primary" @click.stop="push">
        {{ editMode ? $t('Update') : $t('Create') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { credentialsActions } from '@/store/constants.js'

export default {
  name: 'create-edit-account-group',

  props: {
    account: {
      type: Object,
      required: false,
    },
  },

  data: () => ({
    counter: 0,
    keyword: '',
    editMode: false,
    error: false,
    errors: {},
    input: {
      name: '',
      group: '',
      groupConfig: {},
    },
  }),

  mounted() {
    if (this.account) {
      this.editMode = true
      this.input = {
        _id: this.account._id,
        name: this.account.name,
        group: this.account.group,
        groupConfig: _toNumber(this.account.groupConfig),
      }
      this.counter++
    }
  },

  computed: {
    ...mapGetters({
      groupOptions: 'credentials/groupOptions',
      validAccountOptions: 'credentials/validAccountOptions',
    }),
    accounts() {
      const accounts = this.validAccountOptions.filter((el) => {
        if (this.keyword) {
          return el.label.includes(this.keyword)
        }
        return true
      })
      return accounts.sort((a, b) => {
        const _a = this.input.groupConfig[a.value] || 0
        const _b = this.input.groupConfig[b.value] || 1
        return _b - _a
      })
    },
    configs() {
      return [
        {
          key: 'name',
          label: this.$t('Account Group Name'),
        },
        {
          key: 'group',
          type: 'SELECT',
          label: this.$t('Group'),
          options: this.groupOptions,
          placeholder: this.$t('Please select group'),
        },
      ]
    },
  },

  methods: {
    toggle(accountId) {
      if (this.input.groupConfig[accountId]) {
        delete this.input.groupConfig[accountId]
      } else {
        this.input.groupConfig[accountId] = 100
      }
      this.input = { ...this.input }
    },
    async push(event) {
      this.input.groupConfig = _toNumber(this.input.groupConfig)
      const payload = this.input
      if (!this.error) {
        await this.$http.post('/api/v2/account/group', payload)
        await this.$store.dispatch(
          `credentials/${credentialsActions.LOAD_CREDENTIALS}`,
          {
            force: true,
          }
        )
        this.$$closeModal(event)
      }
    },
  },
}

function _toNumber(groupConfig) {
  return Object.fromEntries(
    Object.entries(groupConfig || {}).map(([a, b]) => [a, Number(b)])
  )
}
</script>
