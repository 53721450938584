module.exports = {
  data: () => ({
    key: 1,
    isBatchEdit: false,
    inputs: [],
  }),

  mounted() {
    this.addInput()
  },

  methods: {
    setValue(index, prop, value) {
      if (this.inputs.length > 1 && this.isBatchEdit) {
        this.inputs.forEach((account) => {
          account[prop] = value
        })
      } else {
        this.inputs[index][prop] = value
      }
    },
    removeInput(index) {
      this.inputs.splice(index, 1)
      this.inputs.forEach((account, index) => {
        account.index = index
      })
    },
    addInput() {
      const length = this.inputs.length
      this.inputs.push({ ...(this._default || {}), index: length })
    },
  },
}
